@import "../../../Index.scss";

.wrapper {
  width: 100%;
  padding: 20px;
  h1 {
    margin-top: 20px;
    font-size: 300%;
    font-weight: 100;
  }
  .filters {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .filter {
      width: 250px;
      padding: 10px;
    }
  }
  .games {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .pageIndicator{
    display: flex;
    justify-content: center;
    margin-top:15px;
    margin-bottom: 5px;
  }
  .pager{
    display: flex;
    justify-content: center;
    
    button{
      padding:5px;
      width:100px;
      background-color: transparent;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      cursor: pointer;
    }
    button:disabled{
      background-color: lightgray;
      color:darkgray;
    }
    button:first-child{
      border-left: 1px solid black;
      border-right: 1px solid black;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    button:last-child{
      border-right: 1px solid black;
      border-left: 0px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .noResults {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 600px;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
}

@media screen and(max-width: $larger-mobile-devices), screen and(max-height:700px) {
  .wrapper{
    h1{
      font-size: 200%;
    }
  }
}

@media (max-width: $smaller-mobile-devices) {
  .wrapper{
    h1{
      font-size: 150%;
    }
  }
}
