@import "../../../Index.scss";
.preWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    margin-top: 10px;
    font-size: 120%;
  }
}
.wrapper {
  width: 100%;
  img {
    width: 100%;
  }
}

.wrapper {
  width: 100%;
  .pageArt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    .art {
      background-color: transparent;
      width: 100%;
      background: no-repeat top;
      background-size: cover;
      display: flex;
      justify-content: center;
      flex-grow: 1;
      .content {
        width: 100%;
        max-width: 1020px;
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        .backButton {
          cursor: pointer;
          position: relative;
          right: 15px;
          img {
            width: 30px;
          }
        }
        .left {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          max-width: 500px;
          padding: 10px;
          .heading {
            display: flex;
            align-items: center;
            span {
              background-color: black;
              border-radius: 10px;
              color: white;
              padding: 5px 10px;
              font-size: 80%;
              letter-spacing: 2px;
            }
            .platformIconsWrapper {
              margin-left: 10px;
              img {
                margin-right: 10px;
                width: 25px;
              }
            }
          }
          h1 {
            margin-top:10px;
            font-size: 250%;
          }
          .rating {
            display: flex;
            justify-content: flex-end;
            span {
              font-size: 200%;
              align-self: flex-end;
            }
            img {
              align-self: flex-end;
              margin-left: 10px;
              width: 45px;
            }
          }
          .about {
            max-height: 250px;
            overflow: hidden;
            text-align: justify;
          }
          .about.expanded {
            max-height: inherit;
          }
          .readMoreWrapper {
            margin-top: 10px;
            span {
              background-color: black;
              padding: 5px 10px;
              color: white;
              border-radius: 4px;
              font-size: 80%;
              cursor: pointer;
            }
          }
          .details {
            margin-top: 25px;
            display: flex;
            .detail {
              span {
                display: block;
                color: black;
              }
              span:first-child {
                font-weight: 800;
                margin-bottom: 5px;
              }
            }
            .detail:first-child {
              margin-right: 35px;
            }
          }
          .stores {
            margin-top: 10px;
            span:first-child {
              font-weight: 800;
              display: block;
            }
            a {
              margin-top: 10px;
              margin-right: 5px;
            }
          }
        }
        .right {
          flex-grow: 1;
          display: flex;
          max-width: 400px;
          padding: 10px;
          flex-direction: column;
          img {
            border-radius: 10px;
          }
          .screenshotsWrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: center;
            img {
              margin-top: 5px;
              border: 1px solid transparent;
              width: 49%;
            }
          }
        }
        .productsWrapper {
          width: 100%;
          padding: 10px;
          h2 {
            font-size: 140%;
          }
        }
      }
    }
  }
}

@media screen and(max-width: $larger-mobile-devices), screen and(max-height:700px) {
  .wrapper {
    .pageArt {
      .art {
        .content {
          max-width: 95%;
          .backButton {
            right: 0px;
          }
          .left {
            max-width: 100%;
            h1 {
              font-size: 200%;
            }
          }
          .right {
            max-width: 100%;
            .screenshotsWrapper {
              img {
                width: 45%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $smaller-mobile-devices) {
  .wrapper {
    .pageArt {
      .art {
        .content {
          .right {
            .screenshotsWrapper {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
