@import "../../../Index.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  .filters {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .filter {
      width: 250px;
      padding: 10px;
    }
  }
  .productsContainer {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    .noResults {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 300px;
      }
    }
    .product {
      width: 250px;
      max-width: 100%;
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      box-shadow: 0 4px 8px 0 rgba(245, 136, 108, 0.4);
      border-radius: 5px;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      span {
        margin-top: 2.5px;
        margin-bottom: 2.5px;
      }

      .logo {
        width: 130px;
        max-height: 100px;
        margin-right: 10px;
        flex-shrink: 0;
      }
      .details {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-content: center;
        align-items: center;
        justify-content: space-around;
        .name {
          font-weight: 600;
          font-size: 120%;
        }
        .amount {
          font-weight: 600;
        }
        .cost {
          font-weight: 400;
          span {
            font-size: 80%;
          }
        }
      }
    }
    .product:hover {
      animation-name: selection;
      animation-duration: 0.25s;
      box-shadow: 0 14px 28px rgba(245, 136, 108, 0.6), 0 10px 10px rgba(245, 136, 108, 0.6);
    }
  }
}

@media screen and(max-width: $larger-mobile-devices), screen and(max-height:700px) {
 
}

@media (max-width: $smaller-mobile-devices) {
 
}
