@import "../../../Index.scss";

.wrapper {
  display: flex;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: 400;
    text-align: center;
  }
  h2 {
    font-size: 100%;
    text-align: center;
  }
  .productContainer {
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(245, 136, 108, 0.4);
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 20px 10px 20px;
    width: 90%;
    flex-direction: column;
    .links {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      a:last-child {
        text-align: right;
      }
    }
    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        max-height: 100px;
        max-width: 200px;
      }
    }
    .dataRow {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
    .countriesWrapper {
      display: flex;
      flex-direction: column;
    }
    .label {
      font-size: 100%;
      margin-bottom: 5px;
      font-weight: 600;
    }
    .value {
      font-size: 100%;
    }
    .name {
      text-align: center;
      font-weight: 600;
      font-size: 120%;
    }
    .beneficiaryInfoLabel {
      font-size: 100%;
      margin-top: 10px;
      font-weight: 600;
    }
    .countriesWrapper {
      .container {
        span.single {
          font-size: 100%;
        }
        span.multiple {
          font-size: 60%;
        }
      }
    }
    .rewardsDisclosure {
      margin-top: 20px;
      span {
        font-size: 80%;
      }
    }
  }
  .revoluvip {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    .revoluvipDisclosure {
      text-align: center;
    }
    span {
      margin-top: 10px;
    }
    .revoluvipLogo {
      margin-top: 15px;
      img {
        max-width: 250px;
      }
    }
    .form {
      width: 85%;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .controlGroup {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        input {
          padding: 5px;
          border-radius: 0;
          margin-top: 5px;
          border: 1px solid darkgrey;
        }
        span {
          margin-top: 0px;
          font-size: 70%;
        }
      }
      .controlGroup.error {
        label {
          color: red;
        }
        input {
          color: red;
          border: 1px solid red;
        }
        span {
          color: red;
        }
      }
      .revoluvipError {
        display: flex;
        justify-content: center;
        span {
          color: red;
        }
      }
      .button {
        display: flex;
        margin-top: 10px;
        justify-content: center;
        button {
          padding: 10px;
          background: 0;
          border: 1px solid black;
          border-radius: 4px;
          cursor: pointer;
        }
        button:disabled {
          cursor: inherit;
          background-color: lightgray;
        }
      }
    }
  }
  .proceed {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    width: 85%;
    padding: 20px;
    align-items: center;
    justify-content: center;
    button {
      padding: 15px;
      font-size: 130%;
      font-weight: 500;
      background: 0;
      border: 0;
      background-color: $primary-color;
      color: white;
      border-radius: 4px;
      cursor: pointer;
    }
    button[disabled] {
      background-color: lightgray;
      color: black;
      cursor: default;
    }
    .orderError {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      span {
        color: red;
      }
    }
  }
}
