.wrapper {
  transition: all .2s ease-in-out;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 350px;
  max-width: 100%;
  margin: 5px 10px;
  background-color: #201616;
  overflow: hidden;
  border: 0px solid black;
  border-radius: 12px;
  img {
    width: 100%;
    max-height: 200px;
  }

  .details {
    padding: 15px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    .platforms{
      margin-top:5px;
      display: flex;
      flex-direction: row;
      img.platform{
        margin-right: 10px;
        max-width: 20px;
      }
    }
    span {
      color: white;
      font-size: 140%;
    }
    .data {
      margin-top: 15px;
      display:flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      img.rating{
        width:35px;
        margin-left: 10px;
      }
      .dataPair {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width:100%;
        align-items: stretch;
        span {
          font-size: 80%;
          color: #b6b6b6;
        }
        span.left{
          align-self: flex-end;
        }
        span.right{
          display: flex;
          align-items: flex-end;

        }
      }
      .dataPair:nth-child(even) {
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid rgb(97, 95, 95);
        border-bottom: 1px solid rgb(97, 95, 95);
      }
    }
  }
}
.wrapper:hover{
  transform: scale(1.04);
}
