@import '../../../Index.scss';
.searchbox {
  display: flex;
  background-color: white;
  max-width: 100%;
  min-width:500px;
  min-height: 50px;
  align-items: stretch;
  border: 1px solid $secondary-color;
  border-radius: 4px;
  justify-content: space-between;
  .inputs {
    display: flex;
    align-items: stretch;
    padding: 10px;
    min-width: 0;
    flex-grow: 1;
    input {
      padding-left: 10px;
      min-width: 0;
      flex-grow:1;
      display: flex;
      font-size: 160%;
      outline: none;
      background-color: transparent;
      border: 0;
    }
  }

  .button {
    border:0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    padding: 10px;
    background-color: $secondary-color;
    cursor: pointer;
  }
  .button.disabled{
    visibility: hidden;
  }
}
.searchbox.invalid {
  animation: shake .5s linear;
}
.searchbox.disabled{
  background-color: lightgray;
}
@keyframes shake {
  8%, 41% {
      -webkit-transform: translateX(-10px);
  }
  25%, 58% {
      -webkit-transform: translateX(10px);
  }
  75% {
      -webkit-transform: translateX(-5px);
  }
  92% {
      -webkit-transform: translateX(5px);
  }
  0%, 100% {
      -webkit-transform: translateX(0);
  }
}
@media (max-width: $larger-mobile-devices) {
  .searchbox {
    min-width: 0;
    .inputs {
      flex-direction: column;
      input {
        padding-left: 0;
        font-size: 100%;
      }
    }
  }
}