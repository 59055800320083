@import "../../../Index.scss";
.wrapper {
  max-width: 100%;
  h1 {
    font-size: 160%;
    font-weight: 400;
    text-align: center;
    padding: 20px;
  }
  .loading{
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    span{
      font-size: 140%;
    }
  }
  .welcome {
    display: flex;
    padding: 10px;
    flex-direction: column;
    height: 70vh;
    min-height: 600px;
    align-items: center;
    background-color: transparent;
    background-image: linear-gradient(rgba(15, 15, 15, 0), rgb(21, 21, 21)),
      linear-gradient(rgba(21, 21, 21, 0.8), rgba(21, 21, 21, 0.498039)),
      url("https://media.rawg.io/media/resize/1280/-/screenshots/305/305077ae949966f06120afd8396e498d.jpg");
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    h1,
    h3 {
      text-align: center;
      color: #fff;
      text-shadow: 1px 1px 1px #000;
    }
    h1 {
      padding: 0;
      font-size: 250%;
      font-weight: 400;
      margin-top: 30px;
    }
    h3 {
      font-size: 130%;
      margin-top: 25px;
      font-weight: 300;
      margin-bottom: 60px;
    }
    .disclosure {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 60px;
      width: 100%;
      .wrapper {
        background-color: transparent;
        display: flex;
        padding: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 262px;
        }
        .downloadImgs {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          a {
            padding: 5px;
          }
          img {
            margin-top: 15px;
            width: 200px;
          }
        }
        .description {
          width: 650px;
          margin-top: 10px;
          span {
            a {
              color: #fff;
              font-size: 100%;
            }
            font-weight: 600;
            display: inline-block;
            color: #fff;
            font-size: 140%;
            text-align: center;
          }
        }
      }
    }
  }
  .disclosure {
    display: none;
    margin-top: 20px;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    .wrapper {
      display: flex;
      padding: 15px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 500px;
        max-width: 100%;
      }
      .downloadImgs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        img {
          margin-top: 15px;
          width: 200px;
        }
      }
      h4 {
        font-size: 180%;
        text-align: center;
      }
    }
  }

  .disclaimer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    h1 {
      a {
        text-decoration: none;
        font-size: 120%;
        letter-spacing: 5px;
      }
    }
    .gamesContainer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }
    .genresContainer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;

      .genre {
        transition: all 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        width: 200px;
        max-width: 100%;
        margin: 5px 10px;
        background-color: #201616;
        overflow: hidden;
        border: 0px solid black;
        border-radius: 6px;
        .imgContainer {
          max-height: 100px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        span{
          color:white;
          padding:10px;
        }
      }
      .genre:hover{
        transform: scale(1.1);
      }
    }
    .button {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-self: center;
      padding: 15px 20px;
      cursor: pointer;
      border: 1px solid $primary-color;
      border-radius: 8px;
      font-size: 160%;
      font-weight: 400;
    }
  }

  .references {
    display: flex;
    flex-direction: column;
    padding: 0 0 60px 0;
    .products {
      display: flex;
      margin-top: 40px;
      justify-content: space-around;
      .product {
        position: relative;
        margin: 10px 20px 0 20px;
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background-color: gray;
        }
        .title {
          text-align: center;
          color: white;
          text-decoration: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 2vw;
          margin-bottom: 10%;
          font-weight: 600;
          text-shadow: 1px 1px 1px black;
          transition: 0.5s ease;
        }
        .detail {
          text-align: center;
          color: white;
          text-decoration: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 100%;
          font-weight: 400;
          opacity: 0;
          text-shadow: 1px 1px 1px black;
          transition: 0.6s ease-in-out;
        }
        img {
          max-width: 100%;
        }
      }
      .product:hover {
        .title {
          margin-bottom: 25%;
        }
        .detail {
          opacity: 1;
          margin-bottom: 10%;
        }
        .overlay {
          opacity: 0.3;
        }
      }
    }
  }
}

@media screen and(max-width: $larger-mobile-devices), screen and(max-height:700px) {
  .wrapper {
    .welcome {
      h1 {
        margin-top: 20px;
        font-size: 200%;
      }
      h3 {
        margin-bottom: 20px;
      }
      .searchbox {
        .inputs {
          flex-direction: column;
          input {
            padding-left: 0;
          }
        }
      }
      .disclosure {
        display: none;
      }
    }
    .disclosure {
      display: flex;
      .descriptionResponsive {
        margin-top: 10px;
        span {
          a {
            font-size: 100%;
          }
          font-weight: 600;
          display: inline-block;
          color: $primary-text-color;
          font-size: 140%;
          text-align: center;
        }
      }
    }
    .references {
      .products {
        flex-direction: column;
        align-items: center;
        .product {
          .title {
            font-size: 150%;
            margin-bottom: 10%;
          }
          .detail {
            opacity: 1;
            margin-bottom: 2%;
          }
        }
        .product:hover {
          .title {
            margin-bottom: 10%;
          }
          .detail {
            opacity: 1;
            margin-bottom: 2%;
          }
          .overlay {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

@media (max-width: $smaller-mobile-devices) {
}
